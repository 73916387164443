.account-status-block{
    line-height: 25px;
    padding-top: 15px;
}
.account-status-block-register-link{
    margin: 2px auto;
    border-radius: 5px;
    font-size: 15px;
    cursor: copy;
}
.account-status-block-register-link:hover{
    background-color: rgba(0,0,0,.1);
}