.forms-item-wrap{
    padding: 6px 10px;
    text-align: left;
}
.forms-item-wrap-no-side{
    padding: 6px 0;
}
.forms-item-title{
    font-size: 16px;
    color: #555;
    font-weight: 400;
    letter-spacing: .1em;
    margin-bottom: 4px;
}
.forms-text{
    display: inline-block;
    font-size: 15px;
    padding: 10px;
    height: 18px;
    border: 1px solid #666;
    border-bottom-color: #ccc;
    border-radius: var(--border-radius);
    border-bottom-width: 3px;
    transition: .05s;
}
.forms-text-disabled{
    background-color: #eee;
}
.forms-textarea{
    width: calc(100% - 22px);
    max-width: 330px;
    resize: none;
    height: 80px;
}
.forms-text:focus{
    outline: none;
    border-color: var(--emphasis-color);
}
.forms-text-disabled:focus{
    border-color: #ccc;
}
.forms-text-button{
    display: inline-block;
    height: 42px;
    padding: 0 10px;
    margin-left: 11px;
    letter-spacing: .1em;
    color: white;
    font-size: 17px;
    font-weight: 400;
    border: none;
    background-color: var(--emphasis-color);
    border-radius: var(--border-radius);
    user-select: none;
    cursor: pointer;
    transition: .15s;
}
.forms-text-button:hover{
    background-color: var(--theme-color);
}
.forms-submit-button{
    margin-top: 25px;
    margin-left: 0;
    border:none;
}
.forms-submit-button-decolorized{
    background-color: #ddd;
    color: #333;
    border:none;
}
.forms-submit-button-decolorized:hover{
    background-color: #ccc;
    border:none;
}
.forms-submit-button-warning{
    background-color: #c42400;
}
.forms-submit-button-warning:hover{
    background-color: #ff441a;
}
.forms-select{
    display: block;
    width: 200px;
    height: 42px;
    font-size: 16px;
    padding: 0 10px;
    font-weight: 400;
    outline: none;
    border-radius: var(--border-radius);
    border: 1px solid #666;
}
.forms-option{
    font-size: 16px;
}
.day-and-time-selection-wrap{
    margin: 25px auto;
    padding: 20px 0 20px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.day-and-time-selection-block{
    width: 100%;
    max-width: 300px;
    border-radius: var(--border-radius);
    border: 1px solid #333;
    margin: 3px 10px 10px 10px;
}
.day-and-time-selection-days-timeframe{
    padding: 20px 15px 0 15px;
}
.day-and-time-selection-days-timeframe-input{
    display: block;
    width: 150px;
    height: 30px;
    border-radius: var(--border-radius);
    border: 1px solid #333;
    margin: 10px auto 0 auto;
    padding: 3px 12px;
}
.day-and-time-selection-days-container{
    width: calc(100%);
    margin: 15px 0;
    display: flex;
    padding-right: 6px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.day-and-time-selection-day{
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 17px;
    font-weight: 500;
    margin: 7px 2px 0 2px;
    border-radius: 100%;
    user-select: none;
    -moz-user-select: none;
    transition: .15s;
    cursor: pointer;
}
.day-and-time-selection-day:hover{
    background-color: rgba(0,0,0,.08);
}
.day-and-time-selection-day-selected{
    color: white;
    background-color: var(--emphasis-color);
}
.day-and-time-selection-day-selected:hover{
    background-color: var(--theme-color);
}
.day-and-time-selection-time-container{
    width: calc(110px - 10px);
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}
.day-and-time-selection-time-hint{
    font-size: 16px;
    text-align: center;
}
.day-and-time-selection-time-input{
    width: 100%;
    height: 25px;
    border: none;
    display: block;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
}
.day-and-time-selection-additional-data-input{
    border: 1px solid #ccc;
    height: 35px;
    width: 80%;
    padding: 2px 10px;
    display: block;
    border-radius: var(--border-radius);
    margin: 0 auto;
}
.day-and-time-selection-block-button{
    width: 100%;
    max-width: 300px;
    line-height: 50px;
    height: 50px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid black;
    transition: .15s;
    cursor: pointer;
    margin: 0 10px;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: .15em;
    text-align: center;
}
.day-and-time-selection-block-button:hover{
    background-color: rgba(0,0,0,.05);
}
.forms-check-box-container{
    width: 100%;
    cursor: pointer;
    height: 30px;
    display: flex;
    justify-content: center;
}
.forms-check-box{
    width: 18px;
    height: 18px;
    border: 2px solid #ccc;
    border-radius: 3px;
    margin: 4px;
    overflow: hidden;
    font-size: 0px;
    line-height: 18px;
    text-align: center;
    color: white;
    font-weight: 500;
}
.forms-check-box-checked{
    font-size: 13px;
    border-color: var(--emphasis-color);
    background-color: var(--emphasis-color);
}
.forms-check-box-container:hover > .forms-check-box{
    border-color: var(--emphasis-color);
    background-color: var(--theme-color);
}
.forms-check-box-text{
    width: fit-content;
    line-height: 30px;
    user-select: none;
    height: 30px;
    padding-left: 7px;
    font-weight: 400;
    letter-spacing: .07em;
    color: #333;
}
.date-selection{
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    overflow: visible;
    background-color: white;
}
.date-selection-title{
    border-radius: var(--border-radius);
    padding: 5px 10px;
    background-color: var(--emphasis-color);
    color: white;
    font-size: 19px;
    letter-spacing: .05em;
    width: fit-content;
    font-weight: 300;
    transform: translateX(7px) translateY(-12px);
}
.date-selection-bottom{
    border-top: 1px solid #ccc;
    height: 30px;
    line-height: 30px;
    padding: 10px 18px;
    text-align: right;
    color: var(--emphasis-color);
    cursor: pointer;
    font-size: 17px;
}
.date-selection-bottom:hover{
    text-decoration: underline;
}
.date-selection-table-key{
    text-align: center;
    font-size: 16px;
    line-height: 35px;
}
.day-and-time-selection-table-wrap{
    padding: 20px 0 30px 0;
}
.day-and-time-selection-table-title{
    font-size: 23px;
    font-weight: 300;
    letter-spacing: .1em;
    margin: 0 auto;
    width: 320px;
    padding-bottom: 15px;
    display: flex;
}
.day-and-time-selection-table-title-left{
    width: calc(190px);
}
.day-and-time-selection-table-title-right{
    padding: 7px 10px;
    background-color: var(--emphasis-color);
    color: white;
    border-radius: var(--border-radius);
    font-size: 16px;
    user-select: none;
    cursor: pointer;
}
.day-and-time-selection-table-title-right:hover{
    background-color: var(--theme-color);
}
.day-and-time-selection-table-tabs-container{
    display: flex;
    width: 80%;
    min-width: 320px;
    margin: 0 auto 20px auto;
    border-bottom: 1px solid #ccc;
}
.day-and-time-selection-table-tab{
    width: 100%;
    height: 38px;
    line-height: 32px;
    text-align: center;
    font-size: 17px;
    cursor: pointer;
    user-select: none;
}
.day-and-time-selection-table-tab:hover{
    background-color: rgba(0,0,0,.07);
}
.day-and-time-selection-table-tab-selected{
    box-shadow: inset 0 -5px 0 var(--emphasis-color);
}
.day-and-time-manual-selection-day-container{
    width: calc(80% - 10px);
    padding: 0 5px;
    min-width: 310px;
    margin: 10px auto 0 auto;
}
.day-and-time-manual-selection-top{
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    display: flex;
    user-select: none;
    cursor: pointer;
}
.day-and-time-manual-selection-top:hover{
    background-color: rgba(0,0,0,.05);
}
.day-and-time-manual-selection-top-label{
    width: calc(100% - 30px);
}
.day-and-time-manual-selection-top-button{
    width: 30px;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
}
.day-and-time-manual-selection-time-container{
    background-color: #efefef;
    border-radius: var(--border-radius);
    padding: 10px;
    min-height: 30px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 90px);

}
.day-and-time-manual-selection-time-block{
    width: 70px;
    height: 30px;
    margin: 3px 0;
    padding-right: 10px;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    user-select: none;
    cursor: pointer;
}
.day-and-time-manual-selection-time-block:hover{
    background-color: rgba(0,0,0,.05);
}
.day-and-time-manual-selection-add-container{
    display: flex;
    height: 60px;
    width: fit-content;
    margin: 0 auto;
}
.day-and-time-manual-selection-add-button-container{
    padding-top: 4px;
}
.date-selection-table-block{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.date-selection-table-block:hover{
    background-color: rgba(0,0,0,.07);
}
.date-selection-table-block-selected{
    background-color: var(--theme-color);
}
.date-selection-table-block-selected:hover{
    background-color: var(--emphasis-color);
}
.date-selection-thin-calendar-wrap{
    margin: 0 auto;
    transform: translateX(-4px);
    width: 340px;
    max-width: 100%;
    margin-top: 20px;
}
.subjects-selection{
    display: grid;
    padding: 20px 5px;
    grid-template-columns: repeat(auto-fill, 136px);
    justify-content: center;
}
.subjects-selection-title{
    font-size: 18px;
    text-align: center;
    margin: 8px 0 -8px 0;
}
.subjects-selection-block{
    margin: 5px;
    width: 120px;
    height: 70px;
    border: 3px solid #ccc;
    border-radius: var(--border-radius);
    cursor: pointer;
    display: flex;
}
.subjects-selection-block:hover{
    background-color: rgba(0,0,0,.05);
}
.subjects-selection-block-selected{
    border-color: #00ba00;
    background-color: #f5fff5;
}
.subjects-selection-block-left{
    width: 50px;
    height: 100%;
    background-size: 90% ;
    background-repeat: no-repeat;
    background-position: center 42%;
    margin-left: 5px;
}
.subjects-selection-block-right{
    font-size: 19px;
    font-weight: 300;
    height: fit-content;
    padding: 0 10px 3px 0;
    margin: auto;
}
.subjects-selection-block-thin{
    background-color: white;
    border: 1px solid #888;
}
.days-selection{
    width: 100%;
    min-width: 230px;
    padding-top: 8px;
    padding-left: 10px;
}
.days-selection-days-container{
    display: flex;
}
.days-selection-block{
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    border-radius: 50%;
    margin: 0 2px;
    cursor: pointer;
}
.days-selection-block:hover{
    background-color: rgba(0,0,0,.07);
}
.days-selection-block-selected{
    color: white;
    background-color: var(--emphasis-color);
}
.days-selection-block-selected:hover{
    background-color: var(--theme-color);
}