.subject-chip{
    border-radius: 5000px;
    height: 30px;
    background-color: #ddd;
    width: fit-content;
    margin: 10px 0 0 6px;
    display: flex;
}
.subject-chip-left{
    width: 28px;
    height: 28px;
    margin: 1px;
    background-color: white;
    border-radius: 50%;
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}
.subject-chip-right{
    line-height: 30px;
    font-size: 15px;
    padding: 0 8px 0 3px;    
}