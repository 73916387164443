.notification-shade{
    width: 280px;
    padding: 0 10px;
    position: fixed;
    z-index: 90;
    left: 0;
    top: 100vh;
    transition: .15s;
    transform: translateY(-100%);
}
.notification-block{
    padding: 22px;
    border-radius: var(--border-radius);
    background-color: #222;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.2);
    color: white;
    font-size: 18px;
    font-weight: 300;
    transition: .6s;
    animation: notification-slide .6s;
    margin: 10px 0;
}
.notification-block > a{
    color: var(--theme-color);
    text-decoration: underline;
}
.notification-block-disappear{
    transform: translateX(-400px);
}
@keyframes notification-slide {
    0%{
        transform: translateX(-400px);
    }
    100%{
        transform: translateX(0px);
    }
}