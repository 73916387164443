.volunteer-page-content{
    width: 600px;
    max-width: 95vw;
    padding: 10px 0;
    margin: 0 auto;
}
.volunteer-page-content-columns > .dashboard-block{
    width: 600px;
    max-width: calc(95vw - 42px);
    margin: 25px auto;
}
.class-setting{
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    padding: 10px 15px 20px 15px;
    margin-bottom: 15px;
}
.class-setting-title{
    font-size: 16px;
    color: #666;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}
.information-status{
    width: 100%;
    height: auto;
    border-top: 2px solid var(--emphasis-color);
    margin: 7px 0 25px 0;
}
.information-status-status{
    height: 30px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    user-select: none;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    padding: 10px 15px;
}
.information-status-status:hover{
    background-color: rgba(0,0,0,.05);
}
.information-status-status-text{
    width: calc(100% - 30px);
    line-height: 30px;
    font-size: 17px;
}
.information-status-status-arrow{
    overflow: hidden;
}
.information-status-content-title{
    font-weight: 400;
    font-size: 19px;
    margin-top: 20px;
}
.overview-subjects-condense{
    margin: 1px;
    background-color: transparent;
    padding: 0;
}
.overview-subjects-condense > .subjects-selection-block-thin{
    border-color: #ccc;
}
.overview-class-time{
    margin: 10px 6px;
    display: flex;
}
.overview-class-time > .calendar-view{
    width: 300px;
}
.overview-class-time-time{
    margin: 0 0 0 10px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    width: calc(100% - 295px);
}
.overview-class-time-time-title{
    font-size: 22px;
    line-height: 40px;
    height: 40px;
    font-weight: 300;
    text-align: center;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
}
.overview-class-time-time-container{
    height: calc(100% - 130px);
    border-bottom: 1px solid #ccc;
}
.overview-class-time-link-container{
    height: 80px;
}
.overview-class-time-time-block{
    margin: 8px;
    padding: 9px;
    text-align: center;
    border-radius: 5px;
    background-color: #f2f2f2;
}
.volunteer-class-detail-student{
    background-color: #f2f2f2;
    border-radius: var(--border-radius);
    padding: 10px;
    margin: 10px 5px 0 5px;
    display: flex;
}
.volunteer-class-detail-student-left{
    border-right: 1px solid #ccc;
    width: calc(100% - 120px - 5px - 1px);
    padding-left: 5px;
}
.volunteer-class-detail-student-name{
    font-weight: 500;
    font-size: 17px;
    letter-spacing: .05em;
    padding-bottom: 5px;
}
.volunteer-class-detail-student-right{
    width: 120px;
}
.edit-class-link-block{
    border-radius: var(--border-radius);
    padding: 10px 15px 20px 15px;
    margin: 15px 6px 0 6px;
    border: 1px solid #888;
}
.edit-class-link-block-buttons-container{
    display: flex;
}
.volunteer-init-class-warning{
    padding: 10px;
    border-radius: var(--border-radius);
    border: 3px solid #bd4426;
    background-color: #fff4f3;
}
.volunteer-init-class-warning-content{
    color: #666;
}
.information-status-drop-down-description{
    padding: 0 5px 5px;
    font-size: 16px;
    color: #333;
}
@media(max-width: 550px){
    .overview-class-time > .calendar-view{
        width: 200px;
    }
    .overview-class-time-time{
        width: calc(100% - 210px);
    }
}
@media(max-width: 430px){
    .overview-class-time{
        display: block;
    }
    .overview-class-time > .calendar-view{
        width: auto
    }
    .overview-class-time-time{
        margin: 15px 0 0 0;
        width: auto;
    }
    .overview-class-time-time-container{
        padding-bottom: 5px;
    }
}
