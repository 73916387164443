.users-load-more{
    width: 920px;
    height: 50px;
    line-height: 50px;
    background-color: var(--emphasis-color);
    text-align: center;
    color: white;
    margin: 30px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .1em;
    border: 1px solid #0099dd;
    border-radius: var(--border-radius);
    user-select: none;
    cursor: pointer;
}
.users-load-more:hover{
    background-color: var(--theme-color);
}
.users-list-block{
    font-size: 18px;
    line-height: 70px;
    height: 70px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: .07em;
    font-weight: 300;
}
.users-header-block{
    width: calc(920px - 40px);
    padding: 20px;
    height: 280px;
    background-color: white;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.07);
    margin: 30px;
    overflow-y: hidden;
}
.users-header-block-title{
    font-weight: 400;
    letter-spacing: .05em;
    font-size: 21px;
}
.users-header-block-content{
    width: 100%;
    height: 120px;
    display: flex;
    margin: 20px 0 0 0;
    justify-content: space-between;
}
.users-header-block-item{
    width: calc(100%);
    margin: 5px;
    border: 2px solid #ccc;
    padding: 6px 0 0 0;
    cursor: pointer;
    border-radius: calc(var(--border-radius) * 1.2);
}
.users-header-block-item:hover{
    border-color: #9ed19e;
    background-color: rgba(0,0,0,.025);
}
.users-header-block-item-selected{
    border-color: #00ba00;
    background-color: #f5fff5;
}
.users-header-block-item-selected:hover{
    border-color: #00ba00;
    background-color: #f5fff5;
}