footer{
    background-color: #f3f3f3;
    padding: 60px 0 80px 0;
    margin-top: 50px;
    border-top: 25px solid var(--theme-color);
}
.footer-logo{
    width: 230px;
    height: 60px;
    margin: 0 auto 20px auto;
    background-image: url(../img/logo.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}
.footer-block{
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    color: #333;
    padding: 10px 0 0 0;
    letter-spacing: .05em;
}
.footer-block-contact{
    font-size: 15px;
    padding: 0px;
}