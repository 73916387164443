nav{
    width: 100vw;
    height: 60px;
    border-bottom: 1px solid rgb(190,190,190);
    display: flex;
    position: fixed;
    top: 0;
    z-index: 40;
    background-color: white;
}
.nav-left{
    width: calc(100% - 140px);
    height: 100%;
}
.nav-right{
    width: 110px;
    height: 100%;
    cursor: pointer;
    transition: .15s;
    line-height: 60px;
    font-size: 19px;
    color: var(--emphasis-color);
    font-weight: 500;
    text-align: right;
}
.nav-right:hover{
    text-decoration: underline;
}
.nav-logo{
    width: 160px;
    height: 35px;
    background-image: url(../img/logo.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 17px;
    margin-top: 10px;
}