.progress-bar-container{
    padding: 10px;
    display: flex;
}
.progress-bar-left{
    width: calc(100% - 45px);
    height: 20px;
    border-radius: 50px;
    border: 1px solid #999;
}
.progress-bar-left-content{
    height: 100%;
    background-color: var(--emphasis-color);
}
.progress-bar-right{
    font-size: 14px;
    width: 45px;
    text-align: right;
}