.class-link-element{
    width: 100%;
    height: 100%;
}
.class-link-element-top{
    padding: 0 10px;
    height: 30px;
    line-height: 40px;
    font-size: 14px;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}
.class-link-element-bottom{
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    padding-top: 5px;
}
.class-link-elemet-bottom-button{
    width: 80px;
    height: 38px;
    line-height: 30px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
}
.class-link-elemet-bottom-button-copy{
    cursor: copy;
}
.class-link-elemet-bottom-button:hover{
    background-color: rgba(0,0,0,.07);
}