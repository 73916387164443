.table-bottom{
    border-radius: calc(var(--border-radius) * 1);
    background-color: white;
    margin: 30px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,.06);
    border: 1px solid #ccc;
}
.table-bottom-no-side-padding{
    margin: 30px 0;
}
.table-title-row{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background: #eee;
    display: flex;
    border-bottom: 1px solid #ccc;
}
.table-title-row-block{
    border-right: 1px solid #bbb;
    font-weight: 400;
    font-size: 19px;
    letter-spacing: .1em;
    color: #333;
    text-align: center;
}
.table-title-row-block:last-of-type{
    border: none;
}
.table-rows-container{
    width: 100%;
    height: calc(100vh - 150px);
}
.table-rows-container-noscroll{
    height: auto;
}
.table-row{
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ccc;
}
.table-row-selected{
    background-color: rgb(245, 254, 255);
    box-shadow: inset 5px 0 0 var(--emphasis-color);
}
.table-row-block{
    border-right: 1px solid #ddd;
}