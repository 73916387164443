.activity-classes-table-wrap{
    width: 100vw;
    height: 100vh;
    overflow-x: scroll;
}
.activity-class-row-block{
    min-height: 80px;
    line-height: 80px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    letter-spacing: .05em;
    line-height: 115%;
}
.edit-class-time-container{
    padding: 10px 40px;
}
.activity-classes-preview{
    width: 100%;
    min-height: 300px;
    display: flex;
    padding: 10px;
}
.activity-classes-preview-block{
    margin: 5px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    width: 250px;
    padding: 10px;
    background-color: white;
}
.activity-classes-preview-block-inited{
    background-color: #f5fff5;
    border: 3px solid #00ba00;
}
.activity-classes-preview-block-not-inited{
    border: 3px solid #c5b011;
    background-color: #fffcf0;
}
.activity-classes-overview{
    width: 1482px;
    border-radius: var(--border-radius);
    padding: 10px;
    display: grid;
    background-color: white;
    margin: 30px;
    border: 1px solid #ccc;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
    grid-template-columns: repeat(auto-fit, 70px);
    position: sticky;
    top: 0;
    z-index: 20;
}
.activity-classes-overview-volunteer-name{
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fff4f3;
    box-shadow: inset 0 -3px 0 #bd4426;
}
.activity-classes-overview-volunteer-name-inited{
    box-shadow: inset 0 -3px 0 #00ba00;
    background-color: #f5fff5;
}
.activity-classes-overview-volunteer-name-not-inited{
    box-shadow: inset 0 -3px 0 #c5b011;
    background-color: #fffcf0;
}
.activity-classes-overview-name{
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #fffcf0;
    box-shadow: inset 0 -3px 0 #c5b011;
}
.activity-classes-overview-name-has-class{
    box-shadow: inset 0 -3px 0 #00ba00;
    background-color: #f5fff5;
}
.activity-classes-row{
    margin: 30px;
}
.activity-classes-row > a{
    display: inline-flex;
}
.activity-classes-row > .chip-button{
    display: inline-flex;
}