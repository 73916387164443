.object-attribute-view-block{
    overflow-wrap: break-word;
    padding: 15px;
}
.object-attribute-view-key{
    text-align: right;
}
.object-attribute-view-content{
    font-family: monospace;
    font-size: 17px;
}