.community-name-block{
    width: 100%;
    vertical-align: middle;
    font-size: 19px;
    letter-spacing: .1em;
    line-height: 180px;
    text-align: center;
    user-select: none;
    cursor: pointer;
}
.community-name-block:hover{
    background-color: rgba(0,0,0,.07);
}
.community-activities-row{
    width: calc(100% - 10px);
    height: 100%;
    display: flex;
    padding: 0 5px;
    overflow-y: hidden;
}
.community-activity-block{
    width: 200px;
    height: 150px;
    overflow-y: hidden;
    border-radius: var(--border-radius);
    margin: 14px 5px;
    border: 2px solid #ccc;
    background-color: white;
}
.community-activity-block-activated{
    border: 2px solid #00ba00;
    background-color: #f5fff5;
}
.community-activity-block-planning{
    border: 2px solid #c5b011;
    background-color: #fffcf0;
}
.community-activity-block-ended{
    border: 2px solid #bd4426;
    background-color: #fff4f3;
}
.community-activity-block-top{
    padding: 8px 10px 2px 10px;
    height: calc(100% - 10px - 40px - 1px);
    border-bottom: 1px solid #ccc;
    letter-spacing: .05em;
    font-weight: 400;
    overflow-y: hidden;
}
.community-activity-block-top-description{
    padding: 5px 0;
    font-size: 15px;
    color: #333;
    font-weight: 300;
    height: calc(100% - 10px - 30px);
    overflow-y: hidden;
}
.community-activity-block-bottom{
    display: flex;
    height: 40px;
    padding: 0 10px;
    background-color: #eee;
    justify-content: center;
}
.community-activity-block-bottom-button{
    height: 40px;
    font-size: 16px;
    line-height: 40px;
    user-select: none;
    cursor: pointer;
    width: 70px;
    text-align: center;
}
.community-activity-block-bottom-button:hover{
    background-color: rgba(0,0,0,.07);
}
.community-activity-block-add{
    width: 160px;
    height: 118px;
    margin: 30px 15px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    cursor: pointer;
    user-select: none;
    background-color: white;
}
.community-activity-block-add:hover{
    background-color: rgba(0,0,0,.07);
}
