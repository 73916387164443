.community-banner{
    width: 600px;
    height: 300px;
    border-radius: var(--border-radius);
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.community-banner-content-wrap{
    position: relative;
    top: calc(100% - 170px);
    margin: 0 17px;
}
.community-banner-title{
    width: fit-content;
    max-width: 400px;
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.6);
    color: white;
    font-size: 30px;
    letter-spacing: .1em;
    padding: 5px 12px;
    text-shadow: 0 0 2px rgba(0,0,0,1);
}
.community-banner-subtitle{
    width: fit-content;
    max-width: 500px;
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.6);
    color: white;
    font-size: 15px;
    padding: 5px 12px;
    text-shadow: 0 0 2px rgba(0,0,0,1);
    letter-spacing: .05em;
    margin: 10px 0;
}