@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans+TC:wght@100;400;500&display=swap');
.bottom{
  overflow: hidden;
}
*{
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: 'Noto Sans TC', sans-serif;
  -webkit-tap-highlight-color: rgba(0,0,0,.2);
  -webkit-appearance: checkbox;
  transition: .15s;
  --base-color: #fff;
  --background-theme-color: #e6fdff;
  --theme-color: #70e7ff;
  --emphasis-color: #00cdec;
  --border-radius: 6px;
}
*::selection{background-color: rgba(255, 115, 0, .3)}
body{background-color: #f7f7f7;overflow-x: hidden;}
a{color: unset;text-decoration: none;}
.blank{width:100%;height:40px;}