.calendar-view{
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
}
.calendar-view-top{
    width: 100%;
    height: 37px;
    display: flex;
    overflow-y: hidden;
}
.calendar-view-top-content{
    width: 150px;
    height: 30px;
    margin: 5px 5px 2px calc(100% - 260px - 10px);
    border: 1px solid #ccc;
    border-radius: 3px;
    display: flex;
    background-color: white;
}
.calendar-view-top-content-nochip{
    margin: 5px auto 2px auto;
}
.calendar-view-top-content-button{
    width: 24px;
    height: 100%;
    cursor: pointer;
}
.calendar-view-top-content-button:hover{
    background-color: rgba(0,0,0,.07);
}
.calendar-view-top-content-text{
    height: 30px;
    width: 100px;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: center;
    line-height: 30px;
    font-size: 14px;
}
.calendar-view-content{
    max-width: 480px;
    margin: 20px auto;
}
.calendar-view-content-first-row{
    width: 100%;
    height: 34px;
    display: flex;
    border-bottom: 1px solid #ccc;
}
.calendar-view-content-first-row-block{
    width: 100%;
    height: 100%;
    text-align: center;    
}
.calendar-view-content-grid{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 53px);
}
.calendar-block-text{
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    margin: 9px auto 0 auto;
    text-align: center;
}
.calendar-block-selected > .calendar-block-text{
    background-color: var(--emphasis-color);
    color: white;
}
.calendar-block-text-mini{
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    margin: 5px auto 0 auto;
}
.calendar-block-clickable{
    cursor: pointer;
}
.calendar-block-clickable:hover{
    background-color: rgba(0,0,0,.07);
}
.class-time-view-container{
    margin: 0 auto;
    transform: translateX(-4px);
    width: 340px;
    max-width: 100%;
    margin-top: 20px;
}
.class-time-view-row{
    line-height: 45px;
    text-align: center;
}
.calendar-block-viewing{
    background-color: rgb(207, 255, 255);
}
.calendar-block-viewing:hover{
    background-color: rgb(144, 204, 204);
}