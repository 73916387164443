.class-overview-flex{
    display: flex;
    padding: 10px 20px;
}
.class-overview-side{
    width: 400px;
    padding: 20px 20px;
}
.overview-subjects{
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    border-radius: var(--border-radius);
    margin: 5px 0 10px 0;
    background-color: #f2f2f2;
}
.class-overview-users{
    border-radius: var(--border-radius);
    margin: 5px 0 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    background-color: #f2f2f2;
}
.class-overview-user-block{
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 17px;
    letter-spacing: .05em;
    border-radius: var(--border-radius);
    border: 1px solid #888;
    margin: 5px;
    background-color: white;
}
@media(max-width: 650px){
    .class-overview-flex{
        display: block;
    }
    .class-overview-side{
        width: calc(100% - 40px);
    }
}
@media(max-width: 450px){
    .class-overview-side{
        width: calc(100%);
        padding: 20px 0;
    }
}