.popup-underlay{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    position: fixed;
    z-index: 50;
    top: 0; 
    left: 0;
}
.popup{
    width: 90vw;
    max-width: 800px;
    height: 600px;
    border-radius: var(--border-radius);
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-48%);
}
.popup-tall{
    height: calc(100vh - 180px);
}
.popup-wide{
    width: calc(100vw - 50px);
    min-width: 310px;
    max-width: unset;
}
.popup-thin{
    max-width: 450px;
}
.popup-top{
    height: 42px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: right;
}
.popup-top-close{
    width: 38px;
    height: 38px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-image: url(../ico/close.svg);
    cursor: pointer;
    border-radius: 50%;
    margin: 2px;
}
.popup-top-close:hover{
    background-color: rgba(0,0,0,.1);
}
.popup-content{
    height: calc(100% - 43px);
}
.popup-section{
    padding: 7px 13px 20px 20px;
    border-bottom: 1px solid #eee;
}
.popup-section-sticky{
    position: sticky;
    top: -40px;
    background-color: white;
    padding-bottom: 5px;
    z-index: 25;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
}
.popup-section-title{
    font-weight: 400;
    font-size: 19px;
    letter-spacing: .05em;
    transform: translateX(-4px);
}
.popup-section-subtitle{
    font-size: 16px;
    line-height: 16px;
    color: #777;
    padding-bottom: 10px;;
}
.popup-flex{
    width: 100%;
    display: flex;
}
