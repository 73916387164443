.dashboard-bottom{
    display: flex;
}
nav.left-nav{
    width: 70px;
    height: calc(100vh - 12px);
    padding-top: 10px;
    box-shadow: inset -1px 0 0 0 #ccc;
    display: block;
    background-color: white;
    position: fixed;
    overflow: visible;
    z-index: 40;
}
.nav-block-link{
    display: block;
    width: 70px;
    height: 60px;
}
.nav-block{
    width: 70px;
    height: 30px;
    margin: 20px 0 0 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 28px;
    transition: .15s;
    cursor: pointer;
}
.nav-block:hover{
    transform: translateY(-4px);
}
.dashboard{
    background-image: url(../ico/dashboard-black.svg);
}
.explore{
    background-image: url(../ico/search-black.svg);
}
.tools{
    background-image: url(../ico/heart.svg);
}
.settings{
    background-image: url(../ico/setting-black.svg);
}
.infinitas{
    background-size: 60%;
    background-image: url(../img/logo-crop.png);
}
.hidden{
    cursor: default;
}
.left-nav-quick-action-bottom{
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.38);
    width: 100vw;
    height: 100vh;
}
.left-nav-quick-action{
    width: 90vw;
    max-width: 400px;
    background-color: white;
    top: 200px;
    left: 90px;
    border: 1px solid #ccc;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.08);
    border-radius: var(--border-radius);
    padding-bottom: 25px;
    position: fixed;
}
.left-nav-quick-action-title{
    font-weight: 400;
    font-size: 18px;
    padding: 15px;
}
.left-nav-quick-action-container{
    width: 320px;
    margin: 0 auto;
    text-align: center;
}
.left-nav-quick-action-button{
    display: inline-block;
    width: 140px;
    height: 50px;
    background-color: #eee;
    border-radius: var(--border-radius);
    margin: 5px 8px;
    border: 1px solid transparent;
    user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-align: left;
    vertical-align: top;
}
.left-nav-quick-action-button:hover{
    border-color: #333;
}
.left-nav-quick-action-button-left{
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    margin-left: 6px;
    display: inline-block;
}
.left-nav-quick-action-button-right{
    font-size: 17px;
    user-select: none;
    height: 50px;
    line-height: 50px;
    display: inline-block;
}
.account-name-overlay{
    width: 90px;
    height: 30px;
    line-height: 30px;
    background-color: var(--emphasis-color);
    position: absolute;
    top: 0;
    left: calc(100vw - 90px);
    border-bottom-left-radius: var(--border-radius);
    box-shadow: 0 0 6px 0 rgba(0,0,0,.2);
    color: white;
    text-align: center;
    font-size: 16px;
    z-index: 100;
}
@media(min-width: 701px){
    .left-nav-selected{
        border-left: 3px solid black;
        width: calc(100% - 6px);
    }
}
@media(max-width: 700px){
    .bottom{
        display: block;
    }
    nav.left-nav{
        width: calc(100vw - 40px);
        height: 70px;
        display: flex;
        position: fixed;
        z-index: 10;
        padding: 0 20px 0 20px;
        top: 100%;
        transform: translateY(-100%);
        box-shadow: none;
        border-top: 1px solid #ccc;
    }
    .nav-block-link{
        display: block;
        width: 33%;
        height: 60px;
        overflow: hidden;
    }
    .nav-block{
        width: 100%;
    }
    .hidden{
        display: none;
    }
    .left-nav-selected{
        margin: 0;
        height: 65px;
        border-top: 5px solid black;
    }
    .left-nav-quick-action-bottom{
        top: calc(-100vh + 70px);
        left: 0;
    }
    .left-nav-quick-action{
        top: -20px;
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
    }
}