.user-split-view{
    width: 100%;
    height: 100%;
    display: flex;
}
.user-split-view-left{
    width: calc(100% - 550px - 1px);
    overflow-x: scroll;
}
.user-split-view-right{
    width: calc(550px - 30px);
    border-left: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 0 25px 0 rgba(0,0,0,.12);
    padding: 15px;
}
.user-split-view-right-title{
    font-weight: 400;
}
.user-split-view-right-paragraph{
    padding: 10px 5px 0 5px;
    color: #333;
}
.user-object-attribute-block{
    padding: 10px;
}
.user-object-attribute-key{
    text-align: right;
    overflow-wrap: break-word;
}
.user-preview-block{
    width: calc(800px - 40px);
    height: 320px;
    padding: 15px 20px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.07);
    margin: 30px;
    border-radius: var(--border-radius);
    background-color: white;
    border: 1px solid #ccc;
}
.user-preview-block-title{
    font-size: 20px;
    font-weight: 400;
    letter-spacing: .1em;
    padding-bottom: 10px;
    height: 30px;
}
.user-preview-block-content{
    width: 100%;
    display: flex;
    height: calc(100% - 30px - 10px);
}
.user-preview-block-content-left{
    width: 320px;
    height: 100%;
    border-right: 1px solid #ccc;
}
.user-preview-block-content-right{
    width: calc(100% - 1px - 320px - 20px);
    height: calc(100%);
    padding: 0 20px;
}