.all-classes-preview{
    height: 70px;
    width: calc(100% + 40px);
    margin-left: -20px;
    border-bottom: 1px solid #ccc;
}
.class-time-list-items-container{
    max-height: 250px;
    border: 2px solid #999;
    border-radius: var(--border-radius);
}
.class-time-list-item{
    user-select: none;
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.class-time-list-item:hover{
    background-color: rgba(0,0,0,.05);
}
.class-time-list-item-selected, .class-time-list-item-selected:hover{
    background-color: var(--background-theme-color);
}
.class-time-list-item-title{
    font-weight: 500;
    font-size: 18px;
}
.class-time-list-item-description{
    font-weight: 400;
    color: #666;
}