.comment-section-intro{
    width: 100%;
    border-top: 1px solid #ccc;
    padding-top: 5px;
}
.comment-section-intro-button{
    width: fit-content;
    padding: 7px 12px;
    font-size: 17px;
    cursor: pointer;
    border-radius: var(--border-radius);
    user-select: none;
    color: #666;
    font-weight: 400;
}
.comment-section-intro-button:hover{
    background-color: rgba(0,0,0,.07);
}
.chat-room{
    width: 100%;
    height: 100%;
}
.chat-room-top{
    width: 100%;
    min-height: 150px;
    height: calc(100% - 50px - 1px);
}
.chat-room-top-blank{
    background-position: center 20%;
    background-repeat: no-repeat;
    background-size: 17%;
    opacity: 20%;
}
.chat-room-top-text{
    font-size: 18px;
    text-align: center;
    position: relative;
    font-weight: 500;
    top: 100px;
}
.chat-room-bottom{
    width: 100%;
    height: 50px;
    border-top: 1px solid #ccc;
    display: flex;
}
.chat-room-bottom-input{
    width: calc(100% - 50px - 30px);
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    padding: 0 15px;
    display: block;
    border: none;
    border-right: 1px solid #ccc;
}
.chat-room-bottom-input:focus{
    outline: none;
}
.chat-room-bottom-send{
    line-height: 50px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    user-select: none;
    text-align: center;
    color: var(--emphasis-color);
}
.chat-room-bottom-send:hover{
    background-color: rgba(0,0,0,.05);
}
.message-container{
    height: auto;
    padding: 3px 10px 10px 10px;
    display: flex;
}
.message-bubble{
    padding: 9px 15px;
    background-color: var(--emphasis-color);
    color: white;
    width: fit-content;
    font-size: 17px;
    max-width: calc(100% - 100px);
    border-radius: 20px;
    border-top-left-radius: 0;
}
.message-container-me{
    flex-direction: row-reverse;
}
.message-container-me > .message-bubble{
    color: black;
    background-color: #ddd;
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
}
.message-sender-name{
    font-size: 17px;
    color: #666;
    padding: 10px 10px 0 10px;
}
.message-sender-name-me{
    text-align: right;
}
