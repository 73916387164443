.top-nav-tabs-frame{
    width: 100%;
    height: calc(100vh - 17px);
}
.top-nav-tabs-top{
    width: 100%;
    height: 140px;
    border-bottom: 1px solid #ccc;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 30;
}
.top-nav-tabs-content{
    min-height: calc(100vh - 180px);
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 80px;
}
.top-nav-tabs-title{
    font-weight: 400;
    font-size: 30px;
    height: 35px;
    letter-spacing: .07em;
    padding: 0 10px 10px 20px;
    overflow-y: hidden;
}
.top-nav-tabs-subtitle{
    padding: 15px 10px 0 21px;
    height: 20px;
    overflow-y: hidden;
    font-size: 17px;
    color: #666;
}
.top-nav-tabs-container{
    padding: 15px 20px 0 20px;
    height: 45px;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.top-nav-tabs-container::-webkit-scrollbar{
    display: none;
}
.top-nav-tab-button{
    padding: 0 10px;
    font-size: 18px;
    letter-spacing: .03em;
    color: #333;
    height: 45px;
    line-height: 40px;
    user-select: none;
    cursor: pointer;
    margin: 0 5px;
    display: inline-block;
}
.top-nav-tab-button-selected{
    box-shadow: inset 0 -5px 0 var(--emphasis-color);
}