.class-check-selection {
    padding: 25px;
    background-color: white;
    border-bottom: 1px solid #ccc;
}

.class-check-volunteer {
    margin-bottom: 20px;
}

.class-check-classes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.class-check-class {
    flex: 1;
    margin-right: 20px;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    display: flex;
}

.class-check-class-info {
    width: 119px;
    min-width: 119px;
    padding: 10px;
    border-right: 1px solid #ccc;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.class-check-class-info-class-id {
    font-size: 13px;
    color: #666;
    text-align: center;
}

.class-check-class-info-student-name {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding: 6px 0;
}

.class-check-class-student {
    height: 350px;
    overflow-x: auto;
    white-space: nowrap;
}

.class-check-class-occurrence {
    display: inline-block;
    border-right: 1px solid #ccc;
    width: 200px;
    background-color: #fff4f3;
    height: 330px;
}

.class-check-class-occurrence-completed {
    background-color: #f5fff5;
}

.class-check-class-occurrence-title {
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    padding: 6px 0;
    text-align: center;
}

.class-check-class-info-buttons-container {
    display: flex;
    justify-content: center;
}

.class-check-class-info-stats {
    width: 100%;
    text-align: center;
    user-select: none;
    cursor: pointer;
}

.class-check-class-info-stats:hover {
    background-color: rgba(0, 0, 0, .05);
}

.class-check-class-occurence-content {
    padding: 10px 10px 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.class-check-classes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.single-view-row {
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: white;
    display: flex;
    white-space: nowrap;
}

.single-view-row-left {
    width: 119px;
    line-height: 60px;
    text-align: center;
    background-color: var(--background-theme-color);
    border-right: 1px solid #ccc;
}

.single-view-row-right {
    width: calc(100% - 120px);
    height: 60px;
    overflow-x: auto;
    display: flex;
}

.single-view-row-right-row::-webkit-scrollbar {
    display: none;
}

.single-view-row-classId {
    width: 100px;
    display: inline-block;
    font-size: 11px;
    border-right-color: black;
    position: sticky;
    left: 0;
}

.single-view-row-class-block {
    width: 80px;
    height: 100%;
    border-right: 1px solid #efefef;
    display: inline-block;
    text-align: center;
    background-color: #fff4f3;
}

.single-view-row-class-block-filled {
    background-color: #f5fff5;
}

.multiple-view-row {
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: white;
    display: flex;
}

.multiple-view-row-left {
    width: 69px;
    line-height: 60px;
    text-align: center;
    background-color: var(--background-theme-color);
    border-right: 1px solid #ccc;
}

.multiple-view-row-right {
    width: calc(100% - 70px);
}

.multiple-view-row-right-row {
    width: calc(100%);
    height: 60px;
    overflow-x: auto;
    display: flex;
}

.multiple-view-row-left-classId {
    width: 100px;
    font-size: 11px;
    border-right-color: black;
    position: sticky;
    left: 0;
}

.multiple-view-row-right-row::-webkit-scrollbar {
    display: none;
}

.multiple-view-row-class-block {
    width: 80px;
    height: 100%;
    border-right: 1px solid #efefef;
    display: inline-block;
    text-align: center;
    background-color: #fff4f3;
}

.multiple-view-row-class-block-filled {
    background-color: #f5fff5;
}

/**/

.class-check-selection {
    padding: 25px;
    background-color: white;
    border-bottom: 1px solid #ccc;
}

.class-check-volunteer {
    margin-bottom: 20px;
}

.class-check-classes {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.class-check-class {
    flex: 1;
    margin-right: 20px;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
}

.class-check-class-info {
    padding: 10px;
}

.class-check-class-info-class-id {
    font-size: 13px;
    color: #666;
    text-align: center;
}

.class-check-class-info-student-name {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding: 6px 0;
}

.class-check-class-student {
    height: 350px;
    overflow-x: auto;
    white-space: nowrap;
}

.class-check-class-occurrence {
    display: inline-block;
    border-right: 1px solid #ccc;
    width: 200px;
    background-color: #fff4f3;
    height: 330px;
}

.class-check-class-occurrence-completed {
    background-color: #f5fff5;
}

.class-check-class-occurrence-title {
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    padding: 6px 0;
    text-align: center;
}

.class-check-class-info-buttons-container {
    display: flex;
    justify-content: center;
}

.class-check-class-info-stats {
    width: 100%;
    text-align: center;
    user-select: none;
    cursor: pointer;
}

.class-check-class-info-stats:hover {
    background-color: rgba(0, 0, 0, .05);
}

.class-check-class-occurrence-content {
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.class-check-classes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.class-check-class-occurrence-content {
    padding: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.multiple-view-row {
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: white;
    display: flex;
}

.multiple-view-row-left {
    width: 69px;
    line-height: 60px;
    text-align: center;
    background-color: var(--background-theme-color);
    border-right: 1px solid #ccc;
}

.multiple-view-row-right {
    width: calc(100% - 70px);
}

.multiple-view-row-right-row {
    width: calc(100%);
    height: 60px; /*important*/
    overflow-x: auto;
    display: flex;
}

.multiple-view-row-left-classId {
    width: 100px;
    font-size: 11px;
    border-right-color: black;
    position: sticky;
    left: 0;
}

.multiple-view-row-class-block {
    width: 80px;
    height: 100%;
    border-right: 1px solid #efefef;
    text-align: center;
    background-color: #fff4f3;
}

.multiple-view-row-class-block-filled {
    background-color: #f5fff5;
}

.multiple-view-row-right-row::-webkit-scrollbar {
    display: none;
}
/*
original .class-check-selection{
    padding: 25px;
    background-color: white;
    border-bottom: 1px solid #ccc;
}
.class-check-class{
    width: 100%;
    min-width: 300px;
    border-bottom: 1px solid #ccc;
    background-color: white;
    display: flex;
}
.class-check-class-info{
    width: 119px;
    min-width: 119px;
    padding: 10px;
    border-right: 1px solid #ccc;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
}
.class-check-class-info-class-id{
    font-size: 13px;
    color: #666;
    text-align: center;
}
.class-check-class-info-student-name{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding: 6px 0;
}
.class-check-class-student{
    height: 350px;
    overflow-x: scroll;
    white-space: nowrap;
}
.class-check-class-occurrence{
    display: inline-block;
    border-right: 1px solid #ccc;
    width: 200px;
    background-color: #fff4f3;
    height: 330px;
}
.class-check-class-occurrence-completed{
    background-color: #f5fff5;
}
.class-check-class-occurrence-title{
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    padding: 6px 0;
    text-align: center;
}
.class-check-class-info-buttons-container{
    display: flex;
    justify-content: center;
}
.class-check-class-info-stats{
    width: 100%;
    text-align: center;
    user-select: none;
    cursor: pointer;
}
.class-check-class-info-stats:hover{
    background-color: rgba(0,0,0,.05);
}
.class-check-class-occurence-content{
    padding: 10px 10px 0 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.multiple-view-row{
    width: 100%;
    border-bottom: 1px solid #ccc;
    background-color: white;
    display: flex;
    white-space: nowrap;
}
.multiple-view-row-left{
    width: 69px;
    line-height: 60px;
    text-align: center;
    background-color: var(--background-theme-color);
    border-right: 1px solid #ccc;
}
.multiple-view-row-right{
    width: calc(100% - 70px);
}
.multiple-view-row-right-row{
    width: calc(100%);
    scrollbar-width: none;
    height: 60px;
    overflow-x: scroll;
    overflow-y: hidden;
}
.multiple-view-row-left-classId{
    width: 100px;
    display: inline-block;
    font-size: 11px;
    border-right-color: black;
    position: sticky;
    left: 0;
}
.multiple-view-row-right-row::-webkit-scrollbar{
    display: none;
}
.multiple-view-row-class-block{
    width: 80px;
    height: 100%;
    border-right: 1px solid #efefef;
    display: inline-block;
    text-align: center;
    background-color: #fff4f3;
}
.multiple-view-row-class-block-filled{
    background-color: #f5fff5;
}
*/