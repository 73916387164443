.icon-text-pair{
    height: 30px;
    /* display: flex; */
    overflow-y: hidden;
}
.icon-text-pair-wrap{
    height: unset;
}
.icon-text-pair-icon{
    width: 25px;
    height: 30px;
    background-position: 35% 50%;
    background-repeat: no-repeat;
    background-size: 70%;
    display: inline-block;
    vertical-align: text-top;
}
.icon-text-pair-text{
    max-width: calc(100% - 30px);
    display: inline-block;
    font-size: 17px;
    height: 30px;
    line-height: 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: text-top;
    padding-left: 3px;
}
.icon-text-pair-text-small{
    font-size: 14px;
}
.icon-text-pair-text-wrap{
    height: unset;
    white-space: normal;
}