.class-time-list{
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    margin: 10px;
}
.class-time-list-row{
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    display: flex;
}
.class-time-list-row-date{
    text-align: center;
    width: 140%;
}
.class-time-list-row-time{
    text-align: center;
    width: 100%;
}