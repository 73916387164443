.edit-billboard-items-container{
    margin: 20px;
    height: 500px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
}
.edit-billboard-item{
    padding: 10px 10px 16px;
    display: flex;
    border-bottom: 1px solid #ccc;
}
.edit-billboard-item-add{
    user-select: none;
    cursor: pointer;
    padding-bottom: 8px;
}
.edit-billboard-item-add:hover{
    background-color: rgba(0,0,0,.05);
}