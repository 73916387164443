.chip-button{
    background-color: #e5e5e5;
    border-radius: 500px;
    height: 60px;
    display: flex;
    border: 1px solid white;
    margin-right: 15px;
    user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    width: fit-content;
    border: 1px solid #aaa;
    margin-bottom: 5px;
}
.chip-button:hover{
    border-color: black;
}
.chip-button-left{
    width: 52px;
    height: 52px;
    margin: 4px;
    border-radius: 100%;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
}
.chip-button-right{
    height: 60px;
    padding: 0 30px 0 10px;
    font-size: 18px;
    line-height: 60px;
}