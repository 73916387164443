.expand-drop-down{
    border-bottom: 1px solid #ccc;
}
.expand-drop-down-top{
    height: 40px;
    display: flex;
    user-select: none;
    padding-left: 6px;
    cursor: pointer;
}
.expand-drop-down-top:hover{
    background-color: rgba(0,0,0,.07);
}
.expand-drop-down-top-title{
    height: 40px;
    line-height: 40px;
    font-size: 17px;
    width: calc(100% - 40px);
}
.expand-drop-down-top-indicator{
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    background-image: url(../ico/arrow_down_uni.svg);
}
.expand-drop-down-content{
    padding: 6px 6px 15px 6px;
    margin-bottom: 10px;
}