.activity-students-row{
    margin: 0 30px;
    padding: 2px;
    gap: 5px;
    width: 1900px;
}
.activity-students-row > a{
    display: inline-flex;
}
.activity-students-row > .chip-button{
    display: inline-flex;
}
.activity-students-student-row-block{
    min-height: 50px;
    line-height: 50px;
    padding: 15px 0;
    text-align: center;
    font-size: 18px;
}
.activity-students-student-row-block-reduced{
    height: 50px;
    line-height: 50px;
    overflow-y: hidden;
}
.student-row-block-left{
    text-align: left;
}
.student-row-expanded{
    width: calc(100% - 40px);
    padding: 20px;
    height: 520px;
    background-color: rgb(223, 250, 253);
    display: flex;
}
.student-row-expanded-block{
    background-color: white;
    padding: 0 20px 0 28px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    margin: 0 10px;
}
.student-row-expanded-block-info{
    padding: 0 20px 0 20px;
    width: 300px;
}
.student-row-expanded-block-vertical{
    width: 300px;
    padding: 0 20px 0 20px;
    margin: 0 10px 20px 10px;
}
.student-row-expanded-block-title{
    font-size: 19px;
    font-weight: 500;
    letter-spacing: .1em;
    text-align: center;
    margin: 10px 0 -10px 0;
}
.activity-students-student-row{
    cursor: pointer;
}
.activity-students-student-row:hover{
    background-color: rgba(0,0,0,.04);
}
.activity-students-overview{
    width: 1482px;
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px;
    background-color: white;
    margin: -35px 30px 30px;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
    z-index: 20;
    position: sticky;
    top: 0;
}
.activity-students-overview-names-container{
    display: grid;
    padding-top: 5px;
    grid-template-columns: repeat(auto-fit, 81px);
}