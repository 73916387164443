.coin-data-split{
    width: 100%;
    display: flex;
}
.coin-data-split-left{
    width: 110px;
}
.coin-data-split-right{
    width: 180px;
    min-width: calc(100% - 9px - 110px);
    padding-left: 7px;
    border-left: 1px solid #ccc;
}
.teaching-record-section-title{
    font-size: 19px;
    padding-bottom: 5px;
    font-weight: 500;
    padding-top: 10px;
}
.teaching-record-section-content{
    font-size: 16px;
    padding-bottom: 15px;
}
.teaching-record-volunteer-name{
    width: 110px;
    height: 50px;
    border-radius: var(--border-radius);
    background-color: var(--emphasis-color);
    color: white;
    text-align: center;
    font-size: 18px;
    letter-spacing: .1em;
    line-height: 50px;
    position: relative;
    margin-bottom: -50px;
    left: calc(100% - 110px);
    top: -48px;
}