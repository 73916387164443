.activity-thumbnail{
    border-radius: var(--border-radius);
    min-height: 100px;
    margin: 0 0 13px 0;
    padding: 8px 5px 8px 12px;
    display: flex;
}
.activity-thumbnail-activated{
    border: 3px solid #00ba00;
    background-color: #f5fff5;
}
.activity-thumbnail-planning{
    border: 3px solid #c5b011;
    background-color: #fffcf0;
}
.activity-thumbnail-ended{
    border: 3px solid #bd4426;
    background-color: #fff4f3;
}
.activity-thumbnail-left{
    width: calc(100% - 90px);
    padding-right: 10px;
}
.activity-thumbnail-right{
    width: 80px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 45% 40%;
}
.activity-thumbnail-right-text{
    font-weight: 300;
    font-size: 18px;
    margin-top: 70%;
}
.activity-thumbnail-title{
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 3px;
}
.activity-thumbnail-description{
    font-size: 15px;
    color: #666;
    padding-bottom: 3px;
}
.activity-thumbnail-description-main{
    padding-top: 5px;
    margin-top: 5px;
    border-top: 1px solid #ccc;
}
.student-class-overview{
    display: flex;
}
.student-class-overview-left{
    width: 105%;
    max-width: calc(100% - 2px);
}
.student-class-overview-right{
    width: 100%;
    max-width: calc(100% - 2px);
    padding: 0 8px;
}
.student-class-overview-right-content{
    min-height: 180px;
    height: calc(100% - 2px);
    border-radius: var(--border-radius);
    background-color: #f3f3f3;
    border: 1px solid #ccc;
}
.student-class-overview-date{
    margin: 3px auto;
    text-align: center;
    width: fit-content;
    font-size: 23px;
    font-weight: 300;
    box-shadow: inset 0 -10px 0 var(--theme-color);
    padding: 0 5px;
}
.student-class-overview-class{
    margin: 13px 5px;
    border: 1px solid #ccc;
    padding: 10px 4px;
    background-color: white;
    border-radius: var(--border-radius);
    min-height: 203px;
}
.student-class-overview-class-title{
    padding: 3px 5px 2px 5px;
}
.student-class-overview-class-empty{
    background-position: center 40%;
    background-repeat: no-repeat;
    background-size: 40%;
}
.student-class-overview-class-notice{
    margin: 70% 0 0 0;
    font-size: 17px;
    text-align: center;
}
.add-to-join-block-notice{
    color: #666;
    font-size: 15px;
    padding: 10px;
}
@media(max-width: 570px){
    .student-class-overview{
        display: block;
    }
    .student-class-overview-right{
        margin-top: 13px;
        padding: 0%;
    }
    .student-class-overview-right-content{
        min-height: 240px;
    }
}