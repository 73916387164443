.activity-messaging-student-name{
    padding: 10px;
    text-align: right;
}
.activity-messaging-student-name-name{
    font-weight: 500;
    font-size: 16px;
}
.activity-messaging-student-name-referenceId{
    font-weight: 400;
    color: #666;
    font-size: 14px;
}