.coin-add-container{
    height: 140px;
    padding: 4px 0 0 10px;
}
.coin-container{
    width: 100%;
    height: 70px;
    line-height: 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%;
    filter: drop-shadow(0 0 10px rgba(255, 240, 146, 1)) 
        brightness(1.1);
    font-size: 30px;
    font-family: Georgia;
    text-align: center;
    text-shadow: 0 0 5px white;
}
.coin-add-textbox-container{
    display: flex;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 1px;
    background-color: white;
}
.coin-add-textbox-button{
    width: 27px;
    height: 27px;
    text-align: center;
    line-height: 26px;
    user-select: none;
    cursor: pointer;
}
.coin-add-textbox-button:hover{
    background-color: rgba(0,0,0,.07);
}
.coin-add-textbox{
    display: block;
    width: calc(100% - 27px * 2 - 2px);
    border: unset;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    text-align: center;
    font-size: 17px;
}
.coin-add-button{
    width: 100%;
    height: 33px;
    line-height: 33px;
    background-color: var(--emphasis-color);
    border-radius: var(--border-radius);
    font-size: 16px;
    color: white;
    letter-spacing: .05em;
    text-align: center;
    font-weight: 300;
    margin-top: 5px;
    user-select: none;
    cursor: pointer;
}