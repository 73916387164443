.billboard{
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
}
.billboard-content-block{
    background-color: white;
    height: 20px;
    line-height: 19px;
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.billboard-content-block > a{
    text-decoration: underline;
    padding-left: 6px;
}
.billboard-top-tabs{
    padding: 0 8px;
    height: 50px;
    background-color: white;
    border-bottom: 1px solid #ccc;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.billboard-top-tabs::-webkit-scrollbar{
    display: none;
}
.billboard-top-tab{
    height: 50px;
    line-height: 48px;
    display: inline-block;
    font-size: 17px;
    padding: 0 13px;
    user-select: none;
    cursor: pointer;
}
.billboard-top-tab:hover{
    background-color: rgba(0,0,0,.05);
}
.billboard-top-tab-selected{
    box-shadow: inset 0 -6px 0 var(--emphasis-color);
}
.billboard-content{
    height: 200px;
    background-color: #f3f3f3;
}
.billboard-content-blank{
    background-repeat: no-repeat;
    background-position: center 38%;
    background-size: 80px;
}
.billboard-content-blank-text{
    margin-top: 133px;
    text-align: center;
}