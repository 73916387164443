.class-time-days{
    width: calc(100% - 20px);
    padding: 0 10px;
    height: 50px;
    display: flex;
}
.class-time-days-button-container{
    width: 20px;
    padding: 15px 0;
    overflow-y: hidden;
}
.class-time-days-button{
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1px solid #ccc;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 85%;
    overflow-y: hidden;
    cursor: pointer;
}
.class-time-days-content{
    width: calc(100% - 40px - 20px);
    margin: 0 10px;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-align: left;
}
.class-time-days-content::-webkit-scrollbar{
    display: none;
}
.day-time-wrap{
    width: 100px;
    height: 100%;
    display: inline-flex;
}
.day-time-wrap-day{
    margin: 12px 4px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 14px;
    color: white;
    text-align: center;
    background-color: var(--emphasis-color);
    border-radius: 50%;
}
.day-time-wrap-time{
    padding-left: 5px;
    line-height: 50px;
}