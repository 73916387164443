.user-side-view-frame{
    display: flex;
    height: 100%;
}
.user-side-view-frame-left{
    width: calc(100% - 300px - 1px);
    border-right: 1px solid #ccc;
    overflow-x: scroll;
}
.user-side-view-frame-right{
    padding: 20px;
    width: calc(300px - 40px);
    background-color: white;
    box-shadow: 0 0 25px 0 rgba(0,0,0,.12);
}
.user-side-view-frame-hidden-left{
    width: 100%;
    border-right: none;
}
.user-side-view-frame-hidden-right{
    width: 0%;
    padding: 20px 0;
}
.user-side-view-top{
    display: flex;
    overflow: visible;
}
.user-side-view-title{
    display: block;
    width: calc(100% - 30px);
    font-weight: 400;
    letter-spacing: .1em;
}
.user-side-view-original-string{
    width: 100%;
    padding: 20px;
    transform: translateX(-20px);
    margin: 30px 0;
    background-color: rgb(235, 235, 235);
    font-size: 15px;
    overflow-x: scroll;
}
.user-side-view-original-string-title{
    font-weight: 400;
    text-decoration: underline;
    padding-bottom: 15px;;
}
.user-side-view-original-string-line{
    font-family: monospace;
    padding-bottom: 12px;
    overflow-wrap: break-word;
}