.minor-pane-view{
    width: 92vw;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
}
.minor-pane-view-left{
    width: 142.8%;
    max-width: calc(92vw - 2px - 80px);
    padding: 0 10px 0 18px;
}
.minor-pane-view-right{
    width: 100%;
    max-width: calc(92vw - 2px - 80px);
    padding: 0 18px 0 10px;
}
@media(max-width: 800px){
    .minor-pane-view{
        display: block;
    }
    .minor-pane-view-left{
        margin: 0 auto;
        padding: 0;
    }
    .minor-pane-view-right{
        margin: 0 auto;
        padding: 0;
    }
}
@media(max-width: 700px){
    .minor-pane-view-left, .minor-pane-view-right{
        max-width: calc(92vw - 2px);
    }
}