.dashboard-main{
    width: 95%;
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 0;
}
.dashboard-main-full{
    width: calc(100% - 70px);
    max-width: unset;
    margin: 0 0 0 70px;
    height: 100vh;
    padding: 0;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.dashboard-main-full::-webkit-scrollbar { 
    display: none;
}
.dashboard-main-full-no-bottom-padding{
    padding-bottom: 0;
}
.dashboard-main-column-view{
    width: calc(1080px);
    max-width: unset;
    padding: 40px 0 40px 80px;
    columns: 2;
    column-gap: 0;
}
.dashboard-main-column-view > .dashboard-block{
    display: inline-block;
    width: 480px;
    max-width: 95vw;
    margin: 5px 0;
}
.top-nav-tabs-content > .dashboard-block{
    width: 500px;
    max-width: 85vw;
    margin: 30px auto -10px auto;
}
.dashboard-block{
    padding: 20px;
    border-radius: var(--border-radius);
    margin: 15px 0;
    background-color: white;
    border: 1px solid #ccc;
    
}
.dashboard-block-selected{
    border: 4px solid var(--theme-color);
    padding: 17px;
}
.dashboard-block-title{
    font-weight: 500;
    font-size: 20px;
    letter-spacing: .1em;
}
.dashboard-block-subtitle{
    color: #444;
    font-weight: 400;
    font-size: 15px;
    margin-top: 5px;
}
.dashboard-block-content{
    margin-top: 3px;
    padding: 10px 0;
    overflow: visible;
}
.explore-block{
    padding-top: 110px;
    padding-bottom: 22px;
    height: 140px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: hidden;
}
.explore-block:hover{
    background-position: 50% 55%;
}
.explore-block:hover .explore-block-children-wrap{
    top: 100%;
}
.explore-block-children-wrap{
    padding: 13px 13px 20px 13px;
    position: relative;
    top: 98%;
    transform: translateY(-100%);
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.3);
    color: white;
}
.explore-block-title{
    font-size: 32px;
}
.explore-block-subtitle{
    font-weight: 400;
    font-size: 18px;
    color: white;
}
.dashboard-hint{
    font-size: 17px;
}
@media(max-width: 1150px){
    .dashboard-main-column-view{
        columns: unset;
        column-gap: unset;
        width: 95%;
        max-width: 600px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .dashboard-main-column-view > .dashboard-block{
        display: block;
        width: calc(100% - 40px - 2px);
        max-width: unset;
        margin: 15px auto;
    }
}
@media(max-width: 700px){
    .dashboard-main-full{
        width: calc(100%);
        max-width: unset;
        margin: 0;
    }
}

/* .active{
    box-shadow:inset 0px 1px 0px 0px #fce2c1;
	background:linear-gradient(to bottom, #ffc477 5%, #fb9e25 100%);
	background-color:#ffc477;
	border-radius:11px;
	border:2px solid #eeb44f;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:16px;
	font-weight:bold;
	padding:8px 18px;
	text-decoration:none;
	text-shadow:0px 1px 0px #cc9f52;
} */

.resourcesContentBlocks{
    display: inline-block;
    padding-inline-start: 2cm;
    margin-bottom: 10%;
    block-size: 1000px;
    width: 500px;
}