.select-class-content-flex{
    display: flex;
    padding: 20px 0;
}
.select-class-content-flex-time{
    width: 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 9px;
}
.select-class-content-time{
    padding-left: 0;
    padding-right: 0;
}
.select-class-content-flex-information{
    width: 120%;
    max-width: 100%;
}
.select-class-content-flex-information-text{
    font-size: 17px;
    padding: 4px 4px;
}
.select-class-content-flex-information-subjects{
    padding: 5px;
    background-color: #f3f3f3;
    border-radius: var(--border-radius);
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    margin-bottom: 6px;
}
.select-class-bottom-flex{
    padding-top: 10px;
    display: flex;
}
.select-class-bottom-flex-left{
    cursor: pointer;
    user-select: none;
    border-radius: var(--border-radius);
    margin-top: 12px;
    margin-left: 20px;
}
.select-class-bottom-flex-left:hover{
    background-color: rgba(0,0,0,.07);
}
.select-class-classes-container{
    border: 1px solid #ccc;
    background-color: white;
    border-radius: var(--border-radius);
    margin-top: 15px;
    border-bottom: none;
}
.this-class-block{
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
}
.this-class-block-title-container{
    display: flex;
    cursor: pointer;
}
.this-class-block-title-right{
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    background-image: url(../ico/arrow_down_uni.svg);
}
.this-class-block-title{
    width: calc(100% - 25px);
    font-weight: 500;
    font-size: 17px;
}
.top-nav-tabs-content > .select-class-classes-container{
    width: 100%;
    max-width: 500px;
    margin: 15px auto;
}
