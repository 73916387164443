.activity-setting-quick-action{
    margin: 15px 0 0 0;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
}
.activity-setting-quick-action-left{
    width: calc(100% - 70px);
}
.activity-setting-quick-action-left-title{
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
}
.activity-setting-links-container{
    columns: 2;
    width: fit-content;
    margin: 0 auto;
}
.activity-setting-link::before{
    content: '·';
    color: black;
}
.activity-setting-link{
    font-size: 17px;
    color: var(--emphasis-color);
}
.activity-setting-link:hover{
    text-decoration: underline;
}
.list-user-block{
    width: 100%;
    height: 100%;
    display: flex;
}
.list-user-name, .active-user-name{
    width: 110px;
    padding: 20px 0;
    text-align: right;
}
.list-user-name-title, .active-user-name-title{
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .09em;
}
.list-user-name-subtitle, .active-user-name-subtitle{
    font-size: 11px;
    font-weight: 400;
    color: #666;
}
.list-user-detail{
    width: 250px;
    padding: 10px 0 10px 10px;
}
.list-user-action{
    width: 107px;
    height: 100%;
}
.list-user-action-button-container{
    width: 100%;
    padding-top: 7px;
    display: flex;
    height: 30px;
    justify-content: center;
}
.list-user-action-button{
    border-radius: 4px;
    border: 2px solid #ccc;
    cursor: pointer;
    user-select: none;
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
    margin: 0 3px;
    font-size: 15px;
    text-align: center;
}
.list-user-action-button-confirm{
    border-color: #00ba00;
    background-color: #f5fff5;
}
.list-user-action-button:hover{
    background-color: rgba(0,0,0,.07);
}