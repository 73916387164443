.landing-bottom{
    background-color: white;
}
.landing-block{
    width: 100vw;
    padding-bottom: 145px;
    overflow: visible;
}
.header-block{
    width: calc(95vw);
    margin: 0 auto;
    padding: 180px 2.5vw 30px 2.5vw;
    min-height: 80vh;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    display: flex;
}
.header-block-left{
    height: 500px;
    display: block;
}
.header-block-left{
    width: 40%;
    background-image: url(../img/landing-image.png);
    background-size: 400px;
    background-position: 70% 43%;
    background-repeat: no-repeat;
    transform: rotate(340deg) translateY(-40px);
    animation: 5s bulb-hue infinite linear;
}
@keyframes bulb-hue{
    0%{
        filter: drop-shadow(0 0 130px rgba(104, 247, 252, 1)) drop-shadow(0 0 20px rgba(255, 255, 255, .6)) hue-rotate(0deg);
    }
    100%{
        filter: drop-shadow(0 0 130px rgba(104, 247, 252, 1)) drop-shadow(0 0 20px rgba(255, 255, 255, .6)) hue-rotate(359deg);
    }
}
.large-title{
    width: 60%;
    font-size: 95px;
    font-family: Montserrat;
}
.header-block-right{
    font-size: 80px;
    line-height: 125%;
    font-weight: 500;
    color: #262626;
    padding-left: 50px;
}
.header-block-right-text{
    background: -webkit-linear-gradient(30deg, #1bb4e3, #6ad7eb);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header-block-description{
    width: 300px;
    font-weight: 400;
    padding-left: 22px;
    font-size: 20px;
    color: #aa2222;
    padding-top: 25px;
    letter-spacing: 1px;
    line-height: 160%;
}
.header-block-button{
    border-radius: var(--border-radius);
    height: 57px;
    line-height: 57px;
    width: fit-content;
    -moz-width: -moz-fit-content;
    font-weight: 300;
    letter-spacing: .2em;
    padding: 0 1.2em;
    font-size: 23px;
    background-color: var(--emphasis-color);
    color: white;
    text-align: center;
    margin: 40px 0 0 22px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    transition: .15s;
}
.header-block-button:hover{
    box-shadow: 0 0 12px rgba(46, 207, 240, .6);
}
.header-community-logo{
    width: 290px;
    display: flex;
    height: 80px;
    margin-top: calc(30px + 2vw);
    margin-left: 15px;
    border-radius: 300px;
    background-color: white;
    box-shadow: 0 0 14px 0 rgba(0,0,0,.09);
}
.header-community-logo-left{
    height: 80px;
    width: 84px;
    background-image: url(../img/community-logo.png);
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: center 100%;
}
.header-community-logo-right{
    font-size: 19px;
    line-height: 140%;
    padding: 12px 0 8px 15px;
    font-weight: 500;
    letter-spacing: .12em;
    color: #6b3c0c;
}
.landing-block-title{
    width: 100vw;
    max-width: 690px;
    margin: 0 auto;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    background: -webkit-linear-gradient(30deg, #03abde, #6afdff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.landing-block-title-montserrat{
    font-family: 'Montserrat';
    font-weight: 600;
}
.landing-block-login-cta{
    width: 450px;
    max-width: 95vw;
    height: 130px;
    margin: 20px auto 0 auto;
    border-radius: 1000px;
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.07);
    border: 1px solid rgb(235,235,235);
    display: flex;
    user-select: none;
    -moz-user-select: none;
    /* cursor: pointer; */
    transition: .15s;
}
.landing-block-login-cta-left{
    width: 114px;
    height: 114px;
    margin: 7px 10px 7px 20px;
    background-position-x: 70%;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: 60%;
}
.landing-block-login-cta-right{
    width: calc(100% - 135px - 15px);
    height: 56px;
    line-height: 30px;
    padding: 34px 0 34px 15px;
    font-size: 21px;
    letter-spacing: .15em;
    font-weight: 400;
    color: #555;
    overflow: hidden;
}
.landing-block-note{
    width: 100vw;
    font-size: 17px;
    font-weight: 300;
    text-align: center;
    color: rgb(0,0,0,.7);
}
.landing-block-statistics{
    width: 640px;
    height: 160px;
    max-width: calc(95vw - 60px);
    margin: 0 auto 100px auto;
    border-radius: 15px;
    box-shadow: 0 0 14px 0 rgba(0,0,0,.12);
    padding: 20px 30px;
    display: flex;
}
.landing-block-statistics-item{
    width: 100%;
    height: 100%;
    text-align: center;
}
.landing-block-statistics-item-top{
    font-size: 38px;
    height: 95px;
    overflow: hidden;
    line-height: 130px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: var(--emphasis-color);
}
.landing-block-statistics-item-bottom{
    color: #333;
    font-size: 18px;
    font-weight: 300;
}
.landing-block-activities{
    width: 100vw;
    height: 250px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: 40px;
    overflow-x: scroll;
    white-space: nowrap;
    padding: 40px 0;
    text-align: center;
    scrollbar-width: none;
}
.landing-block-activities::-webkit-scrollbar{
    display: none;
}
.landing-block-explain{
    width: 720px;
    max-width: 95vw;
    border-radius: var(--border-radius);
    margin: 30px auto 55px auto;
    display: flex;
}
.landing-block-explain-item{
    width: 100%;
    overflow-y: hidden;
    padding: 25px 0 30px 0;
}
.landing-block-explain-item-top{
    width: 100%;
    height: 140px;
    padding-top: 5px;
    background-position: 50% 85%;
    background-repeat: no-repeat;
    background-size: 50%;
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, .13)) drop-shadow(0 0 35px rgba(46, 207, 240, .4));
}
.landing-block-explain-item-title{
    height: 30px;
    font-size: 20px;
    font-weight: 400;
    padding-top: 20px;
    text-align: center;
}
.landing-block-explain-item-description{
    padding: 7px 20px 10px 20px;
    font-weight: 300;
    line-height: 180%;
    color: #333;
    text-align: justify;
}
.experience{background-image: url(../color-ico/clock.svg);}
.team{background-image: url(../color-ico/idea.svg);}
.communication{background-image: url(../color-ico/collaboration.svg);}
.volunteer{background-image: url(../color-ico/conference_call.svg);background-size:60%;background-position-y: 0%;}
.community{background-image: url(../color-ico/department.svg);}
.student{background-image: url(../color-ico/reading_ebook.svg);}
.landing-block-image{
    width: 720px;
    height: 370px;
    max-width: 100vw;
    max-height: calc(100vw / 1.9);
    background-image: url(../img/demo.jpg);
    margin: 55px auto 0 auto;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);
    box-shadow: 0 0 70px rgba(46, 207, 240, .2);
    overflow: visible;
}
.landing-block-float-right{
    padding-bottom: 0;
}
.landing-block-float-right::after{
    content: '';
    display: block;
    width: 350px;
    height: 350px;
    max-width: 60vw;
    max-height: 60vw;
    position: relative;
    background-image: url(../img/text.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    left: calc(50% + 50vw);
    top: 120%;
    filter: drop-shadow(0 0 150px rgba(27, 87, 227, 0.6));
    transform: translateX(-70%) rotate(325deg) ;
}
.landing-block-school-grid{
    width: 720px;
    height: 600px;
    max-width: 90vw;
    max-height: calc(90vw / 3 * 2.5);
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    grid-gap: 13px;
}
.landing-block-school-grid-item{
    grid-row: span 1;
    grid-column: span 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%;
    background-position: 50% 47%;
    overflow-y: hidden;
}
.landing-block-school-grid-item-text{
    width: 8.5em;
    font-size: 21px;
    font-weight: 300;
    text-align: center;
    position: relative;
    top: 90%;
    left: 50%;
    transform: translateY(-25px) translateX(-50%);
    letter-spacing: .06em;
    box-shadow: inset 0 -14px 0 rgba(232, 181, 255, .2);
    border-bottom: 2px solid rgba(232, 181, 255, .2);
}
.wego{background-image: url(../img/wego.png);}
.kangchiao{background-image: url(../img/kangchiao.png);}
.kweishan{background-image: url(../img/kweishan.png);}
.chingshin{background-image: url(../img/chingshin.png);}
.scroll-indicator{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid var(--emphasis-color);
    margin: 0 auto 0 auto;
    position: relative;
    top: calc(-100px - 5vh);
    background-position: center;
    background-repeat: no-repeat;
    transition: .5s;
    cursor: s-resize;
}
.scroll-indicator:hover{
    box-shadow: inset 0 -55px 0 var(--emphasis-color);
}
@media(max-width: 1000px){
    .landing-block-float-right{
        padding-bottom: 160px;
    }
    .landing-block-float-right::after{
        display: none;
    }
}
@media(max-width: 900px){
    .header-block{
        display: block;
        padding-bottom: 60px;
        padding-top: 70px;
    }
    .header-block-left, .header-block-right{
        width: 100%;
    }
    .header-block-left{
        background-position-x: 0px;
        margin-left: -25px;
        background-size: 400px;
        height: 460px;
        transform: rotate(340deg) translateY(-60px);
    }
    .large-title{
        font-size: 85px;
    }
    .header-block-right{
        font-size: 80px;
        margin-top: -40px;
        padding-left: 30px;
    }
    .scroll-indicator{
        top: 0%;
        margin-bottom: 100px;
    }
}
@media(max-width: 750px){
    .landing-block-school-grid{
        max-height: calc(90vh);
        grid-template-columns: 1fr 1fr;
    }
    .landing-block-school-grid-item{
        background-size: 55%;
    }
    .landing-block-school-grid-item-text{
        font-size: 19px;
    }
}
@media(max-width: 700px){
    .landing-block-explain{
        display: block;
    }
    .landing-block-explain-item{
        width: 320px;
        margin: 0 auto;
        padding-top: 35px;
    }
    .landing-block-explain-item-top{
        transform: scale(.85);
    }
}
@media(max-width: 570px){
    .landing-block-statistics{
        height: 120px;
    }
    .landing-block-statistics-item-top{
        line-height: 80px;
        height: 65px;
        font-size: 30px;
    }
    .landing-block-statistics-item-bottom{
        font-size: 16px;
    }
}
@media(max-width: 480px){
    .header-block-left{
        background-size: 300px;
        background-position-x: 0px;
        height: 380px;
        width: 90%;
    }
    .header-block-right{
        font-size: 65px;
        padding-left: 0px;
        margin-top: -20px;
    }
    .large-title{
        font-size: 70px;
    }
    .landing-block-title{
        font-size: 35px;
    }
    .landing-block-note{
        font-size: 15px;
    }
    .landing-block-school-grid{
        max-width: 100vw;
        grid-gap: 0px;
    }
    .landing-block-school-grid-item-text{
        font-weight: 400;
        font-size: 17px;
    }
    .landing-block-statistics{
        max-width: calc(95vw - 20px);
        padding: 20px 10px;
    }
    .landing-block-statistics-item-top{
        font-size: 26px;
    }
    .landing-block-statistics-item-bottom{
        font-size: 14px;
    }
}
@media(max-width: 370px){
    .landing-block-login-cta-left{
        width: 90px;
    }
    .landing-block-login-cta-right{
        padding-left: 0;
        font-size: 19px;
        letter-spacing: .08em;
    }
    .landing-block-statistics{
        padding: 14px 0;
        max-width: 99vw;
    }
    .landing-block-statistics-item-top{
        font-size: 24px;
    }
}