.community-student-list-name-top{
    font-size: 18px;
    padding: 5px 9px 0 0;
    text-align: right;
    font-weight: 500;
}
.community-student-list-name-bottom{
    font-size: 13px;
    padding: 0 9px 13px 0;
    text-align: right;
    font-weight: 400;
    color: #666;
}
.community-student-list-standard-block{
    width: 100%;
    height: 70px;
    line-height: 70px;
    font-size: 17px;
    letter-spacing: .02em;
    text-align: center;
}
.community-teaching-records-top{
    width: 450px;
    padding-top: 20px;
}
.community-student-list-note{
    padding: 7px;
}
.community-activities-container{
    width: calc(100% - 60px);
    margin: 30px;
}
.community-students-activity-block{
    width: 300px;
    height: 460px;
    padding: 15px;
    border: 3px solid #ccc;
    border-radius: calc(var(--border-radius) * 1.2);
    display: inline-block;
    margin: 10px;
    background-color: white;
}
.community-students-activity-block-activated{
    border: 3px solid #00ba00;
    background-color: #f5fff5;
}
.community-students-activity-block-planning{
    border: 3px solid #c5b011;
    background-color: #fffcf0;
}
.community-students-activity-block-ended{
    border: 3px solid #bd4426;
    background-color: #fff4f3;
}
.community-students-activity-block-top{
    height: calc(100% - 50px - 1px);
    border-bottom: 1px solid #ccc;
}
.community-students-activity-block-title{
    font-weight: 500;
    font-size: 19px;
    letter-spacing: .03em;
}
.community-students-activity-block-description{
    font-weight: 400;
    font-size: 15px;
    color: #666;
    padding: 5px 2px 10px 2px;
    max-height: 110px;
    overflow: hidden;
    text-align: justify;
}
.community-students-activity-block-info{
    font-weight: 400;
    font-size: 17px;
}
.community-students-activity-block-bottom{
    height: 50px;
    line-height: 55px;
    overflow: hidden;
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: .05em;
    text-align: center;
}
.community-students-record-block-container{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
}
.community-students-record-block-wrap{
    height: 100%;
}
.community-students-record-block{
    width: 300px;
    height: calc(100% - 20px - 4px);
    margin: 10px 0 0 10px;
    border-radius: calc(var(--border-radius) * 1.2);
    border: 2px solid #666;
    background-color: white;
    display: inline-block;
}
.community-students-record-block-content{
    padding: 0 10px;
}
.community-students-record-block-add{
    user-select: none;
    cursor: pointer;
    display: inline-block;
}
.community-students-record-block-add:hover{
    background-color: #f3f3f3;
}
.community-studnets-record-block-add-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}