.login-container{
    width: 100vw;
    height: 100vh;
    display: flex;
}
.left-block{
    flex: 33.33%;
    background-color: #abe9cd;
    /* background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%); */
    background-image: linear-gradient(135deg, #abe9cd 0%, #3eadcf 74%);
}
.right-blcok{
    flex: 66.67%;
    display: flex;
}
.right-section{
    flex: 100%;
    height: fit-content;
    margin-top: 30vh;
}
.right-hint{
    width: 280px;
    margin: 70px auto 0 auto;
    padding: 15px 15px;
    border-radius: calc(2.5 * var(--border-radius));
    border: 6px solid var(--emphasis-color);
    overflow: visible;
}
.right-hint-title{
    font-weight: 400;
    font-size: 18px;
    background-color: #f6f6f6;
    padding: 0 3px 9px 8px;
    margin-left: 5px;
    margin-top: -32px;
    width: fit-content;
    width: -moz-fit-content;
    letter-spacing: .05em;
}
.right-hint-content{
    padding: 0 0 8px 0;
}
.logo-block{
    width: 100%;
    height: 70px;
    margin-top: 30vh;
    background-image: url(../img/logo.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 65%;
}
.slogan-block{
    height: fit-content;
    font-size: 26px;
    text-align: center;
    margin-top: .7rem;
    letter-spacing: .35em;
    line-height: 150%;
    color: #fff;
    font-family: 'Noto Sans TC';
    font-weight: 300;
}
.login-title{
    color: #00cdec;
    font-size: 40px;
    text-align: center;
    padding-bottom: .6rem;
    border-bottom: 1px solid #ccc;
    margin: 0 auto 1.6rem auto;
    font-weight: 400;
    letter-spacing: .1em;
    width: fit-content;
    -moz-width: -moz-fit-content;
}
.input-block{
    max-width: 90%;
    margin: 0 auto 1.5rem auto;
    width: 310px;
}
.input-text{
    padding: 20px;
    border: none;
    width: calc(100% - 2px - 20px * 2);
    outline: none;
    background-color: #f4f8f7;
    border-radius: var(--border-radius);
    display: block;
    height: 20px;
    line-height: 18px;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid transparent;
}
.input-text::placeholder{
    color: #a1a6a5;
}
.input-text:focus{
    border: 1px solid #333;
}
.small-text{
    text-align: center;
    font-size: smaller;
}
.small-text a{
    /* text-decoration: none; */
    color: #5c5c5c;
}
.submit-block{
    margin-top: 5rem;
    text-align: center;
}
.input-submit{
    border: none;
    outline: none;
    background-color: var(--emphasis-color);
    color: #fff;
    padding: 0 16px;
    height: 60px;
    line-height: 56px;
    width: 200px;
    font-size: 16px;
    border-radius: var(--border-radius);
    cursor: pointer;
}
.login-description{
    text-align: center;
    transform: translateY(-37px);
    color: #333;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: .1em;
    max-width: 320px;
    margin: 0 auto;
}
.login-description > a{
    color: var(--emphasis-color);
    text-decoration: underline;
}
.login-description-highlight{
    box-shadow: inset 0 -10px 0 rgba(3, 207, 238, .3);
}
@media(max-width: 700px){
    .login-container{
        display: block;
    }
    .left-block{
        display: none;
    }
    .right-section{
        margin-top: 20vh;
    }
}