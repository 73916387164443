.signup-container{
    max-width: 450px;
    padding: 50px 10px;
    margin: 0 auto;
}
.account-type-selection-item{
    padding: 10px;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    margin: 10px 0;
}
.account-type-selection-item-selected{
    border: 3px solid var(--emphasis-color)
}
.account-type-selection-item:hover{
    background-color: rgba(0,0,0,.05);
}
.account-type-selection-item-title{
    font-size: 16px;
    font-weight: 400;
}
.account-type-selection-item-content{
    font-size: 15px;
    font-weight: 300;
    color: #333;
    padding-top: 5px;
}